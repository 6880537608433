<template>
  <div class="ca-page">
    <v-row>
      <v-col cols="12" class="title-wrap">
        <h4 class="helper-text">Sokal Creative Analysis</h4>
        <h1 class="ca-title home-title mb-5">Defining your dealership</h1>
        <p class="ca-paragraph mb-6">
          Your creative content could go in a million different directions.
          Establish a strong starting point for your dealership with Sokal’s
          Creative Analysis! Defining the strategy, style, and motivation behind your advertising!
        </p>
        <v-select
          v-model="chosenBrand"
          class="remove-flex mb-2"
          :items="brands"
          label="Select Your Brand"
        ></v-select>
        <router-link to="/1" class="r-flex">
          <v-btn color="#ff4438" class="start-btn" x-large rounded>GO</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      chosenBrand: "",
      brands: [
        "Acura",
        "Audi",
        "BMW",
        "Buick",
        "Cadillac",
        "Chevrolet",
        "CJDR",
        "Ford",
        "GMC",
        "Honda",
        "Hyundai",
        "INFINITI",
        "Kia",
        "Lincoln",
        "Mazda",
        "Mitsubishi",
        "Nissan",
        "Subaru",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Other",
      ],
    };
  },
  created() {
    this.$store.dispatch("defaultState");
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  watch: {
    chosenBrand() {
      this.$store.dispatch("chooseBrand", this.chosenBrand);
    },
  },
};
</script>

<style scoped>
.title-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 80px);
  max-width: 600px;
  margin: auto;
}
@media (min-width: 960px) {
  .home-title {
    font-size: 3rem;
  }
}

</style>
